import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, EffectCards, FreeMode } from "swiper/modules";
import "swiper/css/bundle";
import { APP_ROUTES } from "../../router/Route";
import axios from "axios";
import "./SideBar.scss";
import { useNavigate } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";

import userIcon from "../../images/user.svg";
import messangerIcon from "../../images/messangerIcon.svg";
import refferalIcon from "../../images/refIcon.svg";
import ordersIcon from "../../images/orders.svg";
import corporativeIcon from "../../images/corporativeIcon.svg";
import verifyIcon from "../../images/verifyIcon.svg";

function SideBar(props) {
  const logout = () => {
    if (!window.confirm("Вы уверены что хотите выйти из системы?")) return;
    localStorage.removeItem("@token");
    window.location.reload();
  };

  return (
    <>
      <div className="sideBar">
        <div className="adminIcon">
          <div className="adminIconWrapper">
            <div className="iconAdmin">
              <img src={userIcon} alt={userIcon} />
            </div>
            <h3>Admin - {props.title}</h3>
          </div>
        </div>
        <div className="sideBarNav">
          <Link reloadDocument to={APP_ROUTES.CORPORATIVE} className={`navLink ${props.title === "Корпоративка" && 'isActive'}`}>
            <div className="navLinkIcon">
              <img src={corporativeIcon} alt={corporativeIcon} />
            </div>
            <h4>Корпоративка</h4>
          </Link>
          <Link reloadDocument to={APP_ROUTES.BID} className={`navLink ${props.title === "Заявки" && 'isActive'}`}>
            <div className="navLinkIcon">
              <img src={messangerIcon} alt={messangerIcon} />
            </div>
            <h4>Заявки</h4>
          </Link>
          <Link reloadDocument to={APP_ROUTES.REFERRAL} className={`navLink ${props.title === "Рефералка" && 'isActive'}`}>
            <div className="navLinkIcon">
              <img src={refferalIcon} alt={refferalIcon} />
            </div>
            <h4>Рефералка</h4>
          </Link>
          <Link reloadDocument to={APP_ROUTES.ORDERS} className={`navLink ${props.title === "Заказы" && 'isActive'}`}>
            <div className="navLinkIcon">
              <img src={ordersIcon} alt={ordersIcon} />
            </div>
            <h4>Заказы</h4>
          </Link>
          <Link reloadDocument to={APP_ROUTES.VERIFICATION} className={`navLink ${props.title === "Верификация" && 'isActive'}`}>
            <div className="navLinkIcon">
              <img src={verifyIcon} alt={verifyIcon} />
            </div>
            <h4>Верификация</h4>
          </Link>
   
        </div>
        {/* <div className="sideBarNotifications">
          <h3>Уведомления</h3>

          <div className="notificationItem">
            <div className="notificationIcon"></div>
            <div className="notificationMessage">
              <h4>Новый заказ</h4>
              <p>Сегодня в 12:00</p>
            </div>
          </div>
        </div> */}
        <div className="logout">
          <button onClick={() => logout()}>Выйти</button>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default SideBar;
