import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { APP_ROUTES } from "../../router/Route";

import { LineChart } from "@mui/x-charts/LineChart";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SideBar from "../SideBar/SideBar";
import TopSideBar from "../TopSideBar/TopSideBar";

import "./Referral.scss";

import updateIcon from "../../images/updateIcon.svg";
import deleteIcon from "../../images/deleteIcon.svg";
import eyeIcon from "../../images/eye.png";
import arrowLine from "../../images/ArrowLine.svg";

const xLabels = Array.from({ length: 31 }, (_, i) => i + 1);

const Referral = () => {
  const [isOpenSideBar, setIsOpenSideBar] = useState(true);
  const [uData, setUData] = useState([]); // прошлый месяц
  const [pData, setPData] = useState([]); // текущий месяц
  const [rate, setRate] = useState(0);
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [topRefs, setTopRefs] = useState([]);
  const [updatedRate, setUpdatedRate] = useState(0);
  const [isOpenCoursePopup, setIsOpenCoursePopup] = useState(false);
  const [isOpenTopRefPopup, setIsOpenTopRefPopup] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPageUsers, setCurrentPageUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});

  const title = "Рефералка";

  const toggleSideBar = (boolValue) => {
    setIsOpenSideBar(boolValue);
  };

  const getStatistics = async () => {
    try {
      const response = await axios.get(`${APP_ROUTES.URL}/admin/new-users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("@token")}`,
        },
      });

      setUData(response.data.lastMonth.map((item) => item.count));
      setPData(response.data.thisMonth.map((item) => item.count));
    } catch (error) {
      console.error("Произошла ошибка при получении статистики");
    }
  };

  const getRate = async () => {
    try {
      const response = await axios.get(`${APP_ROUTES.URL}/admin/rate`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("@token")}`,
        },
      });

      setRate(+response.data);
    } catch (error) {
      console.error("Произошла ошибка при получении курса E-coin");
    }
  };

  const showUser = (id, usersObj) => {
    const foundUser = usersObj.find((item) => item.id === id);

    setSelectedUser(foundUser);
    setIsOpenTopRefPopup(true);
  };

  const getAllUsersByPagination = async (page) => {
    try {
      const response = await axios.get(
        `${APP_ROUTES.URL}/admin/users/${page - 1}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("@token")}`,
          },
        }
      );

      setTotalPages(Math.ceil(response.data.count / 10));
      setCurrentPageUsers(response.data.users);
    } catch (error) {
      console.error("Произошла ошибка при получении пользователей");
    }
  };

  const getTotalReferrals = async () => {
    try {
      const response = await axios.get(
        `${APP_ROUTES.URL}/admin/referral/count`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("@token")}`,
          },
        }
      );

      setTotalReferrals(response.data);
    } catch (error) {
      console.error("Произошла ошибка при получении кол-ва рефералов");
    }
  };

  const updateRate = async (newRate) => {
    if (newRate <= 0) {
      toast.error("Курс не может быть отрицательным или равным 0");
      return;
    }

    if (newRate === rate) {
      toast.error("Курс не изменен, введите другое значение");
      return;
    }

    try {
      await axios.post(
        `${APP_ROUTES.URL}/admin/rate`,
        { rate: newRate },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("@token")}`,
          },
        }
      );

      toast.success("Курс успешно изменен");
      getRate();
    } catch (error) {
      toast.error("Произошла ошибка при изменении курса");
    }
  };

  const changeUsersPage = (event, value) => {
    getAllUsersByPagination(value);
  }

  const getAllTopRefs = async () => {
    try {
      const response = await axios.get(
        `${APP_ROUTES.URL}/admin/top-referrals`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("@token")}`,
          },
        }
      );

      setTopRefs(response.data);
    } catch (error) {
      console.error("Произошла ошибка при получении топ рефералов");
    }
  };

  useEffect(() => {
    getStatistics();
    getRate();
    getAllTopRefs();
    getTotalReferrals();
    getAllUsersByPagination(1);
  }, []);

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isOpenSideBar && <SideBar title={title} />}
      <div className="mainInfo">
        <TopSideBar
          title={title}
          toggleSideBar={toggleSideBar}
          isOpenSideBar={isOpenSideBar}
        />

        <div
          className={`refPopupContainer ${!isOpenCoursePopup && "hidden"}`}
          onClick={() => setIsOpenCoursePopup(false)}
        >
          <div className="refPopup" onClick={(e) => e.stopPropagation()}>
            <div className="courseUpdateForm">
              <h4>Курс E-Coin</h4>
              <input
                type="number"
                value={updatedRate}
                placeholder="курс в суммах"
                min={0}
                onChange={(e) => setUpdatedRate(e.target.value)}
              />
              <p>Текущий курс: 1 E-coin - {rate} сум</p>
              <button onClick={() => updateRate(+updatedRate)}>
                Изменить курс
              </button>
            </div>
          </div>
          <div
            className="closePopup"
            onClick={() => setIsOpenCoursePopup(false)}
          ></div>
        </div>

        <div
          className={`refPopupContainer ${!isOpenTopRefPopup && "hidden"}`}
          onClick={() => setIsOpenTopRefPopup(false)}
        >
          <div className="refPopup" onClick={(e) => e.stopPropagation()}>
            <div className="courseUpdateForm">
              <h4>Информация о пользователе</h4>
              <label>Имя пользователя</label>
              <input type="text" readOnly value={selectedUser.fullname} />
              <label>Логин</label>
              <input type="text" readOnly value={selectedUser.email} />
              <label>Номер телефона</label>
              <input type="text" readOnly value={selectedUser.phone_number} />
              <label>Город</label>
              <input type="text" readOnly value={selectedUser.city} />
              <label>Всего ссылок</label>
              <input type="text" readOnly value={selectedUser.total_links} />
              <label>Кол-во баллов</label>
              <input type="text" readOnly value={selectedUser.balance} />
              <label>Баланс</label>
              <input
                type="text"
                readOnly
                value={`${(selectedUser.balance * rate).toLocaleString()} сум`}
              />
            </div>
          </div>
          <div
            className="closePopup"
            onClick={() => setIsOpenTopRefPopup(false)}
          ></div>
        </div>

        <div className="mainInfoContainer">
          <div className="refferalTools">
            <div className="toolsWrapper">
              <div className="referralCards">
                <div className="referralCard">
                  <h3>Курс E-Coin</h3>
                  <p>
                    1 E-coin - <b>{rate}</b> сум
                  </p>
                  <div className="referralCardBtns">
                    <button onClick={() => setIsOpenCoursePopup(true)}>
                      Изменить Курс
                    </button>
                  </div>
                </div>
                <div className="referralCard">
                  <h3>Гайды</h3>
                  <p>
                    Всего: <b>35</b> гайдов
                  </p>
                  <div className="referralCardBtns">
                    <button>Посмотреть</button>
                    <button>Создать</button>
                  </div>
                </div>
                <div className="referralCard">
                  <h3>Созданные ссылки</h3>
                  <p>
                    Всего: <b>{totalReferrals}</b> Ссылок
                  </p>
                </div>
              </div>
              <div className="toolsChart">
                <LineChart
                  height={300}
                  series={[
                    { data: pData, label: "Текущий месяц" },
                    { data: uData, label: "Прошлый месяц" },
                  ]}
                  xAxis={[{ scaleType: "point", data: xLabels }]}
                />
              </div>
            </div>
            <div className="topReferrals">
              <h3>Топ Рефералы</h3>
              <div className="topReferralsContainer">
                {topRefs.map((item, index) => (
                  <div className="topRef" key={index}>
                    <div className="topRefInfo">
                      <h4>{item.fullname}</h4>
                      <p>{item.balance} E-coin</p>
                    </div>
                    <div className="topRefLook">
                      <img
                        src={eyeIcon}
                        alt={eyeIcon}
                        // onClick={() => setIsOpenTopRefPopup(true)}
                        onClick={() => showUser(item.id, topRefs)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="table choosenStep">
            <div className="tableWrapper">
              <h3>Все пользователи "реферальной системы"</h3>
              <div className="tableHeader">
                <div className="tableHeaderItem smallItem">ID</div>
                <div className="tableHeaderItem">Партнер (ФИО)</div>
                <div className="tableHeaderItem">Логин</div>
                <div className="tableHeaderItem">Номер Телефона</div>
                <div className="tableHeaderItem">Кол-во ссылок</div>
                <div className="tableHeaderItem smallItem">Статус</div>
              </div>
              {currentPageUsers.map((item, index) => (
                <div className="tableBody" key={index}>
                  <div className="tableBodyItemWrapper">
                    <div className="tableBodyItem smallItem">{item.id}</div>
                    <div className="tableBodyItem">{item.fullname}</div>
                    <div className="tableBodyItem">{item.email}</div>
                    <div className="tableBodyItem">{item.phone_number}</div>
                    <div className="tableBodyItem">{item.total_links}</div>
                    <div className="tableBodyItem smallItem">
                      {item.status ? "Успешно" : "Неуспешно"}
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="tableBody">
                <div className="tableBodyItemWrapper">
                  <div className="tableBodyItem smallItem">1</div>
                  <div className="tableBodyItem">Амиров Амир Амирович</div>
                  <div className="tableBodyItem">amirovAmir</div>
                  <div className="tableBodyItem">+998912345678</div>
                  <div className="tableBodyItem">86</div>
                  <div className="tableBodyItem smallItem">Успешно</div>
                </div>
              </div> */}
            </div>
            <div className="pagination">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  color="primary"
                  // onPageChange={(e) => console.log(e)}
                  onChange={changeUsersPage}
                />
              </Stack>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Referral;
