import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, EffectCards, FreeMode } from "swiper/modules";
import "swiper/css/bundle";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { APP_ROUTES } from "../../router/Route";
import axios from "axios";
import "./Bid.scss";
import { useNavigate } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import updateIcon from "../../images/updateIcon.svg";
import deleteIcon from "../../images/deleteIcon.svg";

import SideBar from "../SideBar/SideBar";
import TopSideBar from "../TopSideBar/TopSideBar";

function Bid() {
  const title = "Заявки";
  const [isOpenSideBar, setIsOpenSideBar] = useState(true);
  const [allBids, setAllBids] = useState([]);

  useEffect(() => {
    updateAllStates();
  }, []);


  const updateAllStates = () => {
    getAllBids();
  };

  const toggleSideBar = (boolValue) => {
    setIsOpenSideBar(boolValue);
  };




  const getAllBids = async () => {
    try {
      const response = await axios.get(`${APP_ROUTES.URL}/bid`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("@token")}`,
        },
      });

      setAllBids(response.data);
    } catch (error) {
      toast.error("Произошла ошибка при загрузке продуктов");
    }
  };






  const removeBid = async (id) => {
    if (window.confirm("Вы уверены что хотите удалить продукт?")) {
      try {
        const response = await axios.delete(
          `${APP_ROUTES.URL}/bid/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("@token")}`,
            },
          }
        );

        toast.success("Заявка успешно удалена");
        updateAllStates();
      } catch (error) {
        toast.error("Произошла ошибка при удалении заявки");
      }
    }
  };








  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isOpenSideBar && <SideBar title={title} />}
      <div className="mainInfo">
        <TopSideBar
          title={title}
          toggleSideBar={toggleSideBar}
          isOpenSideBar={isOpenSideBar}
        />
        <div className="mainInfoContainer">


          {allBids.length > 0 && (
            <div
              className={`table choosenStep`}
            >
              <div className="tableWrapper">
                <div className="tableHeader">
                  <div className="tableHeaderItem smallItem">ID</div>
                  <div className="tableHeaderItem">Имя</div>
                  <div className="tableHeaderItem">Фамилия</div>
                  <div className="tableHeaderItem">Номер телефона</div>
                  <div className="tableHeaderItem">Сообщение</div>
                  <div className="tableHeaderItem ">Дата</div>
                  <div className="tableHeaderItem smallItem">Удалить</div>
                </div>
                <div className="tableBody">
                  {allBids.map((product) => (
                    <div className="tableBodyItemWrapper" key={product.id}>
                      <div className="tableBodyItem smallItem">
                        {product.id}
                      </div>
                      <div className="tableBodyItem">{product.name}</div>
                      <div className="tableBodyItem">
                      {product.surname}
                      </div>
                      <div className="tableBodyItem">{product.phone}</div>
                      <div className="tableBodyItem tableBodyMessage">{product.message}</div>
                      <div className="tableBodyItem ">
                        {product.created_at.slice(0, 10)}
                      </div>
                      <div className="tableBodyItem smallItem">
                        <div
                          className="remove"
                          onClick={() => removeBid(product.id)}
                        >
                          <img src={deleteIcon} alt={deleteIcon} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}


        </div>
      </div>

      <Outlet />
    </>
  );
}

export default Bid;
