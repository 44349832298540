import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APP_ROUTES } from "../../router/Route";
import axios from "axios";
import "./Verification.scss";
import { useNavigate } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import SideBar from "../SideBar/SideBar";
import TopSideBar from "../TopSideBar/TopSideBar";
import InfoModal from "./InfoModal";

function Verification() {
  const title = "Верификация";
  const [isOpenSideBar, setIsOpenSideBar] = useState(true);
  const [allOrders, setAllOrders] = useState([]);

  useEffect(() => {
    updateAllStates();
  }, []);

  const updateAllStates = () => {
    getAllOrders();
  };

  const toggleSideBar = (boolValue) => {
    setIsOpenSideBar(boolValue);
  };

  const getAllOrders = async () => {
    try {
      const response = await axios.get(`${APP_ROUTES.URL}/profile/all`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("@token")}`,
        },
      });

      setAllOrders(response.data);
      console.log(response.data);
    } catch (error) {
      toast.error("Произошла ошибка при загрузке продуктов");
    }
  };

  const updateOrderStatus = async (id, isVerified) => {
    try {
      await axios.patch(
        `${APP_ROUTES.URL}/profile/one/${id}`,
        { isVerified },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("@token")}`,
          },
        }
      );
      toast.success("Статус успешно обновлен");
      updateAllStates();
    } catch (error) {
      toast.error("Произошла ошибка при обновлении статуса");
    }
  };

  const translateStatus = (isVerified) => {
    if (isVerified) {
      return "Подтвержден";
    }
    return "Не подтвержден";
  };

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isOpenSideBar && <SideBar title={title} />}
      <div className="mainInfo">
        <TopSideBar
          title={title}
          toggleSideBar={toggleSideBar}
          isOpenSideBar={isOpenSideBar}
        />
        <div className="mainInfoContainer">
          {allOrders.length > 0 && (
            <div className={`table choosenStep`}>
              <div className="tableWrapper">
                <div className="tableHeader">
                  <div className="tableHeaderItem  tableId">ID</div>
                  <div className="tableHeaderItem">Имя Фамилия</div>
                  <div className="tableHeaderItem">Город</div>
                  <div className="tableHeaderItem">Номер телефона</div>
                  <div className="tableHeaderItem ">Дата</div>
                  <div className="tableHeaderItem">Информация</div>
                  <div className="tableHeaderItem">Статус</div>
                  
                </div>
                <div className="tableBody">
                  {allOrders.map((profile) => (
                    <div className="tableBodyItemWrapper" key={profile.id}>
                      <div className="tableBodyItem tableId">
                        {profile.user.id}
                      </div>
                      <div className="tableBodyItem">
                        {profile.name} {profile.user.fullname}
                      </div>
                      <div className="tableBodyItem">{profile.user.city}</div>
                      <div className="tableBodyItem">{profile.user.phone_number}</div>
  
                      <div className="tableBodyItem">
                        {profile.user.created_at.slice(0, 10)}
                      </div>
          

                      <div className="tableBodyItem">
                        <InfoModal info={profile.info} images={profile.images}/>
                      </div>
                      <div className="tableBodyItem tableBodyMessage">
                        <select
                          value={profile.user.isVerified}
                          onChange={(e) =>
                            updateOrderStatus(profile.user.id, e.target.value)
                          }
                        >
                          {[true,false].map(
                            (status) => (
                              <option key={status} value={status}>
                                {translateStatus(status)}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      
                      
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default Verification;
