import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APP_ROUTES } from "../../router/Route";
import axios from "axios";
import "./Orders.scss";
import { useNavigate } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import SideBar from "../SideBar/SideBar";
import TopSideBar from "../TopSideBar/TopSideBar";

function Orders() {
  const title = "Заказы";
  const [isOpenSideBar, setIsOpenSideBar] = useState(true);
  const [allOrders, setAllOrders] = useState([]);

  useEffect(() => {
    updateAllStates();
  }, []);

  const updateAllStates = () => {
    getAllOrders();
  };

  const toggleSideBar = (boolValue) => {
    setIsOpenSideBar(boolValue);
  };

  const getAllOrders = async () => {
    try {
      const response = await axios.get(`${APP_ROUTES.URL}/orders`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("@token")}`,
        },
      });

      setAllOrders(response.data);
    } catch (error) {
      toast.error("Произошла ошибка при загрузке продуктов");
    }
  };

  const updateOrderStatus = async (id, status) => {
    try {
      await axios.post(
        `${APP_ROUTES.URL}/orders/orderStatus`,
        { order_id:id ,  status: status },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("@token")}`,
          },
        }
      );
      toast.success("Статус успешно обновлен");
      updateAllStates();
    } catch (error) {
      toast.error("Произошла ошибка при обновлении статуса");
    }
  };

  const translateStatus = (status) => {
    const translations = {
      NEW: "Новый",
      IN_PROGRESS: "В процессе",
      DONE: "Завершено",
      TRASH: "Удалено",
      REJECTED: "Отклонено",
    };
    return translations[status] || status;
  };

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isOpenSideBar && <SideBar title={title} />}
      <div className="mainInfo">
        <TopSideBar
          title={title}
          toggleSideBar={toggleSideBar}
          isOpenSideBar={isOpenSideBar}
        />
        <div className="mainInfoContainer">
          {allOrders.length > 0 && (
            <div className={`table choosenStep`}>
              <div className="tableWrapper">
                <div className="tableHeader">
                  <div className="tableHeaderItem smallItem">ID</div>
                  <div className="tableHeaderItem">Имя Фамилия</div>
                  <div className="tableHeaderItem">Город</div>
                  <div className="tableHeaderItem">Номер телефона</div>
                  <div className="tableHeaderItem">Товар</div>
                  <div className="tableHeaderItem ">Дата</div>
                  <div className="tableHeaderItem">Кол-во</div>
                  <div className="tableHeaderItem">Статус</div>
                  
                </div>
                <div className="tableBody">
                  {allOrders.map((product) => (
                    <div className="tableBodyItemWrapper" key={product.id}>
                      <div className="tableBodyItem smallItem">
                        {product.id}
                      </div>
                      <div className="tableBodyItem">
                        {product.name} {product.surname}
                      </div>
                      <div className="tableBodyItem">{product.city}</div>
                      <div className="tableBodyItem">{product.phone}</div>
                      <div className="tableBodyItem">
                        {product.Product.title}
                      </div>
                      
                      <div className="tableBodyItem">
                        {product.created_at.slice(0, 10)}
                      </div>
                      <div className="tableBodyItem tableBodyMessage">
                        {product.count}
                      </div>
                      <div className="tableBodyItem tableBodyMessage">
                        <select
                          value={product.status}
                          onChange={(e) =>
                            updateOrderStatus(product.id, e.target.value)
                          }
                        >
                          {["NEW", "IN_PROGRESS", "DONE", "TRASH", "REJECTED"].map(
                            (status) => (
                              <option key={status} value={status}>
                                {translateStatus(status)}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default Orders;
