import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import showIcon from "../../images/showIcon.svg";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: 12,
  boxShadow: 24,
  width: '80%',
  height: '80%', // Adjust height to fit content
  p: 4,
  display: 'flex', // Use flexbox to layout items
  flexDirection: 'row', // Arrange items horizontally
  gap: 2, // Add spacing between columns
};

const tableData = [
  'Имя',
  'Инн',
  'Рег. код НДС',
  'Адрес',
  'Паспорт',
  'Пинфл',
  'Срок действия паспорта',
  'Адрес по прописке',
  'Телефон',
  'Расчетный счет',
  'Название банка',
  'МФО',
  'Номер пластиковой карты',
];

const tableValues = [
  'name',
  'inn',
  'regCode',
  'address',
  'passport',
  'pinfl',
  'passportDate',
  'passportAddress',
  'phone',
  'bankAccount',
  'bankName',
  'mfo',
  'cardNumber',
];

export default function InfoModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const info = JSON.parse(props.info);

  return (
    <div>
      <Button onClick={handleOpen} >
        <img src={showIcon} alt="Show Info" />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ flex: 1,overflowY: 'auto' }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Информация
            </Typography>
            <div className="tableInfo choosenStep">
              <div className="tableWrapper">
                <div className="tableHeader">
                  <div className="tableHeaderItem">Название</div>
                  <div className="tableHeaderItem">Значение</div>
                </div>
                <div className="tableBodyInfo">
                  {info && tableData.map((item, index) => (
                    <div className="tableBodyItemWrapper" key={item}>
                      <div className="tableBodyItem">{item}</div>
                      <div className="tableBodyItem">
                        {info[tableValues[index]] || "Не указано"}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Box>
          <Box sx={{ flex: 1, overflowY: 'auto' }}>
            {props.images.length > 0 && (
              props.images.map((image) => (
                <div key={image.id} className="imageContainer">
                  <img src={image} alt="Image" style={{ width: '100%', height: 'auto', borderRadius: 8 }} />
                </div>
              ))
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
