import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, EffectCards, FreeMode } from "swiper/modules";
import "swiper/css/bundle";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { APP_ROUTES } from "../../router/Route";
import axios from "axios";
import "./Corporative.scss";
import { useNavigate } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import updateIcon from "../../images/updateIcon.svg";
import deleteIcon from "../../images/deleteIcon.svg";

import SideBar from "../SideBar/SideBar";
import TopSideBar from "../TopSideBar/TopSideBar";

function Corporative() {
  const title = "Корпоративка";
  const [isOpenSideBar, setIsOpenSideBar] = useState(true);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [choosenColor, setChoosenColor] = useState(1);
  const [choosenCreateStep, setChoosenCreateStep] = useState(1);
  const [superCategoryTitle, setSuperCategoryTitle] = useState("");
  const [superCategoryUpdate, setSuperCategoryUpdate] = useState(false);
  const [superCategoryId, setSuperCategoryId] = useState(0);
  const [allSuperCategories, setAllSuperCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [choosenSuperCategory, setChoosenSuperCategory] = useState(0);
  const [createCategoryTitle, setCreateCategoryTitle] = useState("");
  const [categotyId, setCategoryId] = useState(0);
  const [categoryUpdate, setCategoryUpdate] = useState(false);
  const [productUpdate, setProductUpdate] = useState(false);
  const [productId, setProductId] = useState(0);
  const [productObject, setProductObject] = useState({
    title: "",
    compound: "",
    category_id: 0,
    action: "",
    price: 0,
    description: "",
    count: 0,
    testimony: "",
    contraction: "",
    image: {
      images: [],
    },
    color: choosenColor,
    extra: {
      info1: ["", ""],
      info2: ["", ""],
      info3: ["", ""],
    },
  });

  useEffect(() => {
    updateAllStates();
  }, []);

  const changeColor = (color) => {
    setChoosenColor(color);
    setProductObject((prev) => ({
      ...prev,
      color: color,
    }));
  };

  const updateAllStates = () => {
    getAllSuperCategories();
    getAllCategories();
    getAllProducts();
  };

  const toggleSideBar = (boolValue) => {
    setIsOpenSideBar(boolValue);
  };

  const getAllSuperCategories = async () => {
    try {
      const response = await axios.get(`${APP_ROUTES.URL}/super-category`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("@token")}`,
        },
      });

      setAllSuperCategories(response.data.superCategories);
    } catch (error) {
      toast.error("Произошла ошибка при загрузке супер категорий");
    }
  };

  const getAllCategories = async () => {
    try {
      const response = await axios.get(`${APP_ROUTES.URL}/category`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("@token")}`,
        },
      });

      setAllCategories(response.data);
    } catch (error) {
      toast.error("Произошла ошибка при загрузке категорий");
    }
  };

  const getAllProducts = async () => {
    try {
      const response = await axios.get(`${APP_ROUTES.URL}/products`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("@token")}`,
        },
      });

      setAllProducts(response.data);
    } catch (error) {
      toast.error("Произошла ошибка при загрузке продуктов");
    }
  };

  const createCategory = async () => {
    if (!createCategoryTitle || !choosenSuperCategory)
      return toast.error("Введите все данные!");
    try {
      const response = await axios.post(
        `${APP_ROUTES.URL}/category`,
        {
          title: createCategoryTitle,
          super_category_id: choosenSuperCategory,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("@token")}`,
          },
        }
      );

      toast.success("Категория успешно создана");
      updateAllStates();
    } catch (error) {
      toast.error("Произошла ошибка при создании категории");
    }
  };

  const createSuperCategory = async () => {
    if (!superCategoryTitle) return toast.error("Введите все данные!");
    try {
      const response = await axios.post(
        `${APP_ROUTES.URL}/super-category`,
        {
          title: superCategoryTitle,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("@token")}`,
          },
        }
      );

      toast.success("Супер категория успешно создана");
      updateAllStates();
    } catch (error) {
      toast.error("Произошла ошибка при создании супер категории");
    }
  };

  const uploadPhoto = async (photo) => {
    const formData = new FormData();
    formData.append("file", photo);

    try {
      const response = await axios.post(
        `${APP_ROUTES.URL}/products/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("@token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setProductObject((prev) => ({
        ...prev,
        image: {
          images: [...prev.image.images, response.data],
        },
      }));
    } catch (error) {
      throw error;
    }
  };

  const createProduct = async () => {
    if (
      !productObject.title ||
      productObject.image.images.length < 1 ||
      !productObject.category_id ||
      !productObject.price ||
      !productObject.count
    ) {
      if (productObject.image.images.length < 1)
        toast.error("Выберите минимум 2 фото");
      if (!productObject.title) toast.error("Введите Заголовок");
      if (!productObject.category_id) toast.error("Выберите категорию");
      if (!productObject.price) toast.error("Введите цену");
      if (!productObject.count) toast.error("Введите количество");

      return;
    }

    try {
      const response = await axios.post(
        `${APP_ROUTES.URL}/products`,
        productObject,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("@token")}`,
          },
        }
      );

      toast.success("Продукт успешно создан");
      updateAllStates();
      cancelUpdateProduct();
    } catch (error) {
      toast.error("Произошла ошибка при создании продукта");
    }
  };

  const removeProduct = async (id) => {
    if (window.confirm("Вы уверены что хотите удалить продукт?")) {
      try {
        const response = await axios.delete(
          `${APP_ROUTES.URL}/products/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("@token")}`,
            },
          }
        );

        toast.success("Продукт успешно удален");
        updateAllStates();
      } catch (error) {
        toast.error("Произошла ошибка при удалении продукта");
      }
    }
  };

  const removeSuperCategory = async (id) => {
    if (window.confirm("Вы уверены что хотите удалить супер категорию?")) {
      try {
        const response = await axios.delete(
          `${APP_ROUTES.URL}/super-category/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("@token")}`,
            },
          }
        );
        updateAllStates();
      } catch (error) {
        toast.error("Произошла ошибка при удалении супер категории");
      }
    }
  };

  const removeCategory = async (id) => {
    if (window.confirm("Вы уверены что хотите удалить категорию?")) {
      try {
        const response = await axios.delete(
          `${APP_ROUTES.URL}/category/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("@token")}`,
            },
          }
        );
        updateAllStates();
      } catch (error) {
        toast.error("Произошла ошибка при удалении категории");
      }
    }
  };

  const handleFileInputChange = async (event) => {
    if (event.target.files.length > 2) {
      toast.error("Максимальное количество фото 2");
      return;
    }

    const files = event.target.files;
    const orderedFilesByName = Array.from(files).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setSelectedPhotos(orderedFilesByName);

    try {
      const uploadPromises = Array.from(files).map(uploadPhoto);
      await Promise.all(uploadPromises);
    } catch (error) {
      toast.error("Произошла ошибка при загрузке фото");
    }
  };

  const changeSuperCategory = (id) => {
    setSuperCategoryId(id);
    setSuperCategoryUpdate(true);
    const superCategory = allSuperCategories.find(
      (superCategory) => superCategory.id === id
    );

    setSuperCategoryTitle(superCategory.title);
  };

  const updateSuperCategory = async () => {
    try {
      const response = await axios.patch(
        `${APP_ROUTES.URL}/super-category/${superCategoryId}`,
        {
          title: superCategoryTitle,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("@token")}`,
          },
        }
      );

      toast.success("Супер категория успешно изменена");
      updateAllStates();
      setSuperCategoryUpdate(false);
      setSuperCategoryTitle("");
    } catch (error) {
      toast.error("Произошла ошибка при изменении супер категории");
    }
  };

  const cancelUpdateSuperCategory = () => {
    setSuperCategoryTitle("");
    setSuperCategoryUpdate(false);
  };

  const changeCategory = (id) => {
    setCategoryId(id);
    setCategoryUpdate(true);
    const category = allCategories.find((category) => category.id === id);

    setCreateCategoryTitle(category.title);
    setChoosenSuperCategory(category.super_category_id);
  };

  const updateCategory = async () => {
    try {
      const response = await axios.patch(
        `${APP_ROUTES.URL}/category/${categotyId}`,
        {
          title: createCategoryTitle,
          super_category_id: choosenSuperCategory,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("@token")}`,
          },
        }
      );

      toast.success("Категория успешно изменена");
      updateAllStates();
      setCreateCategoryTitle("");
      setChoosenSuperCategory(0);
      setCategoryUpdate(false);
    } catch (error) {
      toast.error("Произошла ошибка при изменении категории");
    }
  };

  const cancelUpdateCategory = () => {
    setCreateCategoryTitle("");
    setChoosenSuperCategory(0);
    setCategoryUpdate(false);
  };

  const changeProduct = (id) => {
    const product = allProducts.find((product) => product.id === id);
    console.log(product, "product");
    setProductId(id);
    setProductUpdate(true);
    setChoosenColor(product.color);
    const updatedPhotos = selectedPhotos.length > 0 ? selectedPhotos : product.image.images;
    console.log(updatedPhotos, "updatedPhotos");
    console.log(product.image.images, "product.image.images");

    setProductObject({
      title: product.title,
      compound: product.compound,
      category_id: product.category_id,
      action: product.action,
      price: product.price,
      description: product.description,
      count: product.count,
      testimony: product.testimony,
      contraction: product.contraction,
      image: {
        images: updatedPhotos,
      },
      color: product.color,
      extra: {
        info1: product.extra.info1,
        info2: product.extra.info2,
        info3: product.extra.info3,
      },
    });
  };

  const updateProduct = async () => {
    try {
      const response = await axios.patch(
        `${APP_ROUTES.URL}/products/${productId}`,
        productObject,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("@token")}`,
          },
        }
      );

      toast.success("Продукт успешно изменен");
      updateAllStates();
      cancelUpdateProduct();
    } catch (error) {
      toast.error("Произошла ошибка при изменении продукта");
    }
  };

  const cancelUpdateProduct = () => {
    setProductUpdate(false);
    setChoosenColor(1);
    setSelectedPhotos([]);
    document.getElementById("file-input").value = "";
    setProductObject({
      title: "",
      compound: "",
      category_id: 0,
      action: "",
      price: 0,
      description: "",
      count: 0,
      testimony: "",
      contraction: "",
      image: {
        images: [],
      },
      color: choosenColor,
      extra: {
        info1: ["", ""],
        info2: ["", ""],
        info3: ["", ""],
      },
    });
  };

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isOpenSideBar && <SideBar title={title} />}
      <div className="mainInfo">
        <TopSideBar
          title={title}
          toggleSideBar={toggleSideBar}
          isOpenSideBar={isOpenSideBar}
        />
        <div className="mainInfoContainer">
          <div className="mainInfoCardsContainer">
            <div className="mainInfoCard">
              <div className="cardTitle">Продукты</div>
              <div className="cardQty">{allProducts.length}</div>
              <div className="plusIcon" onClick={() => setChoosenCreateStep(1)}>
                +
              </div>
            </div>
            <div className="mainInfoCard">
              <div className="cardTitle">Супер Категории</div>
              <div className="cardQty">{allSuperCategories.length}</div>
              <div className="plusIcon" onClick={() => setChoosenCreateStep(2)}>
                +
              </div>
            </div>
            <div className="mainInfoCard">
              <div className="cardTitle">Категории</div>
              <div className="cardQty">{allCategories.length}</div>
              <div className="plusIcon" onClick={() => setChoosenCreateStep(3)}>
                +
              </div>
            </div>
          </div>

          <div className="corporativeCreateWrapper">
            <div
              className={`corporativeCreate ${
                choosenCreateStep === 1 && "choosenStep"
              }`}
            >
              <h2 className="headingCreate">
                {productUpdate ? "Изменить Продукт" : "Новый Продукт"}
              </h2>
              <div className="formInputs">
                <div className="textInputsWrapper">
                  <div className="textInputsLine">
                    <div className="textInput">
                      <label>Наименование товара *</label>
                      <input
                        type="text"
                        value={productObject.title}
                        onChange={(e) =>
                          setProductObject({
                            ...productObject,
                            title: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="textInput">
                      <label>Наминальная ценность (сум) *</label>
                      <input
                        type="number"
                        value={
                          productObject.price === 0 ? "" : productObject.price
                        }
                        onChange={(e) =>
                          setProductObject({
                            ...productObject,
                            price:
                              e.target.value === ""
                                ? null
                                : Number(e.target.value),
                          })
                        }
                      />
                    </div>
                    <div className="textInput">
                      <label>Действие препората</label>
                      <input
                        type="text"
                        value={productObject.action}
                        onChange={(e) =>
                          setProductObject({
                            ...productObject,
                            action: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="textInput">
                      <label>Противопоказание</label>
                      <input
                        type="text"
                        value={productObject.contraction}
                        onChange={(e) =>
                          setProductObject({
                            ...productObject,
                            contraction: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="textInput">
                      <label>Количество *</label>
                      <input
                        type="number"
                        value={
                          productObject.count === 0 ? "" : productObject.count
                        }
                        onChange={(e) =>
                          setProductObject({
                            ...productObject,
                            count:
                              e.target.value === ""
                                ? null
                                : Number(e.target.value),
                          })
                        }
                      />
                    </div>
                    <div className="textInput">
                      <label>1 Заголовок "Доп. Информация"</label>
                      <input
                        type="text"
                        value={productObject.extra.info1[0]}
                        onChange={(e) =>
                          setProductObject({
                            ...productObject,
                            extra: {
                              ...productObject.extra,
                              info1: [
                                e.target.value || "",
                                productObject.extra.info1[1],
                              ],
                            },
                          })
                        }
                      />
                    </div>
                    <div className="textInput">
                      <label>2 Заголовок "Доп. Информация"</label>
                      <input
                        type="text"
                        value={productObject.extra.info2[0]}
                        onChange={(e) =>
                          setProductObject({
                            ...productObject,
                            extra: {
                              ...productObject.extra,
                              info2: [
                                e.target.value || "",
                                productObject.extra.info2[1],
                              ],
                            },
                          })
                        }
                      />
                    </div>
                    <div className="textInput">
                      <label>3 Заголовок "Доп. Информация"</label>
                      <input
                        type="text"
                        value={productObject.extra.info3[0]}
                        onChange={(e) =>
                          setProductObject({
                            ...productObject,
                            extra: {
                              ...productObject.extra,
                              info3: [
                                e.target.value || "",
                                productObject.extra.info3[1],
                              ],
                            },
                          })
                        }
                      />
                    </div>
                    <div className="buttonsWrapper">
                      <button
                        onClick={() =>
                          productUpdate ? updateProduct() : createProduct()
                        }
                      >
                        {productUpdate ? "Изменить" : "Создать"}
                      </button>
                      <button onClick={() => cancelUpdateProduct()}>
                        Отмена
                      </button>
                    </div>
                  </div>
                  <div className="textInputsLine">
                    <div className="textInput">
                      <label>Категория товара *</label>
                      <select
                        name=""
                        id=""
                        value={productObject.category_id}
                        onChange={(e) =>
                          setProductObject({
                            ...productObject,
                            category_id: Number(e.target.value),
                          })
                        }
                      >
                        <option hidden value="">
                          Выберите категорию
                        </option>
                        {allCategories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="textInput">
                      <label>Состав</label>
                      <input
                        type="text"
                        value={productObject.compound}
                        onChange={(e) =>
                          setProductObject({
                            ...productObject,
                            compound: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="textInput">
                      <label>Показание</label>
                      <input
                        type="text"
                        value={productObject.testimony}
                        onChange={(e) =>
                          setProductObject({
                            ...productObject,
                            testimony: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="textAreaInput">
                      <label>Описание</label>
                      <textarea
                        name=""
                        id=""
                        cols="15"
                        rows="10"
                        value={productObject.description}
                        onChange={(e) =>
                          setProductObject({
                            ...productObject,
                            description: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                    <div className="textInput">
                      <label>1 Описание "Доп. Информация"</label>
                      <input
                        type="text"
                        value={productObject.extra.info1[1]}
                        onChange={(e) =>
                          setProductObject({
                            ...productObject,
                            extra: {
                              ...productObject.extra,
                              info1: [
                                productObject.extra.info1[0],
                                e.target.value || "",
                              ],
                            },
                          })
                        }
                      />
                    </div>
                    <div className="textInput">
                      <label>2 Описание "Доп. Информация"</label>
                      <input
                        type="text"
                        value={productObject.extra.info2[1]}
                        onChange={(e) =>
                          setProductObject({
                            ...productObject,
                            extra: {
                              ...productObject.extra,
                              info2: [
                                productObject.extra.info2[0],
                                e.target.value || "",
                              ],
                            },
                          })
                        }
                      />
                    </div>
                    <div className="textInput">
                      <label>3 Описание "Доп. Информация"</label>
                      <input
                        type="text"
                        value={productObject.extra.info3[1]}
                        onChange={(e) =>
                          setProductObject({
                            ...productObject,
                            extra: {
                              ...productObject.extra,
                              info3: [
                                productObject.extra.info3[0],
                                e.target.value || "",
                              ],
                            },
                          })
                        }
                      />
                    </div>
                    <div className="colorChoose">
                      <div className="colorItems">
                        <div
                          className={`colorItem ${
                            choosenColor === 2 && "active"
                          }`}
                          onClick={() => changeColor(2)}
                        >
                          <div className="colorBox white"></div>
                          <h3>Светлая тема</h3>
                        </div>
                        <div
                          className={`colorItem ${
                            choosenColor === 1 && "active"
                          }`}
                          onClick={() => changeColor(1)}
                        >
                          <div className="colorBox black"></div>
                          <h3>Темная тема</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="imageInputsWrapper">
                  <label>Выберите Фото *</label>
                  <input
                    className="selectImageIconInput"
                    type="file"
                    id="file-input"
                    placeholder="Загрузить фото"
                    multiple
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => handleFileInputChange(e)}
                  />
                  <div className="uploadedPhoto">
                    {selectedPhotos.length > 0 &&
                      Array.from(selectedPhotos).map((photo, index) => (
                        <div key={index} className="uploadedPhotoItem">
                          <img src={URL.createObjectURL(photo)} alt="" />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`corporativeCreate ${
                choosenCreateStep === 2 && "choosenStep"
              }`}
            >
              <h2 className="headingCreate">
                {superCategoryUpdate
                  ? "Изменить Супер Категорию"
                  : "Новая Супер Категория"}
              </h2>
              <div className="formInputs">
                <div className="textInputsWrapper">
                  <div className="textInputsLine">
                    <div className="textInput">
                      <label>Наименование Супер Категории *</label>
                      <input
                        type="text"
                        value={superCategoryTitle}
                        onChange={(e) => setSuperCategoryTitle(e.target.value)}
                      />
                    </div>
                    <div className="buttonsWrapper">
                      <button
                        onClick={() =>
                          superCategoryUpdate
                            ? updateSuperCategory()
                            : createSuperCategory()
                        }
                      >
                        {superCategoryUpdate ? "Изменить" : "Создать"}
                      </button>
                      <button onClick={() => cancelUpdateSuperCategory()}>
                        Отмена
                      </button>
                    </div>
                  </div>
                  <div className="textInputsLine"></div>
                </div>
              </div>
            </div>

            <div
              className={`corporativeCreate ${
                choosenCreateStep === 3 && "choosenStep"
              }`}
            >
              <h2 className="headingCreate">
                {categoryUpdate ? "Изменить Категорию" : "Новая Категория"}
              </h2>
              <div className="formInputs">
                <div className="textInputsWrapper">
                  <div className="textInputsLine">
                    <div className="textInput">
                      <label>Наименование Категории *</label>
                      <input
                        type="text"
                        value={createCategoryTitle}
                        onChange={(e) => setCreateCategoryTitle(e.target.value)}
                      />
                    </div>
                    <div className="textInput">
                      <label>К какой супер категории принадлежит *</label>
                      <select
                        name=""
                        id=""
                        value={choosenSuperCategory}
                        onChange={(e) =>
                          setChoosenSuperCategory(+e.target.value)
                        }
                      >
                        <option hidden value="">
                          Выберите супер категорию
                        </option>
                        {allSuperCategories.map((superCategory) => (
                          <option
                            key={superCategory.id}
                            value={superCategory.id}
                          >
                            {superCategory.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="buttonsWrapper">
                      <button
                        onClick={() =>
                          categoryUpdate ? updateCategory() : createCategory()
                        }
                      >
                        {categoryUpdate ? "Изменить" : "Создать"}
                      </button>
                      <button onClick={() => cancelUpdateCategory()}>
                        Отмена
                      </button>
                    </div>
                  </div>
                  <div className="textInputsLine"></div>
                </div>
              </div>
            </div>
          </div>

          {allProducts.length > 0 && (
            <div
              className={`table ${
                choosenCreateStep === 1 && "choosenStep"
              }`}
            >
              <div className="tableWrapper">
                <div className="tableHeader">
                  <div className="tableHeaderItem smallItem">ID</div>
                  <div className="tableHeaderItem">Наименование</div>
                  <div className="tableHeaderItem">Категория</div>
                  <div className="tableHeaderItem">Цена</div>
                  <div className="tableHeaderItem">Количество</div>
                  <div className="tableHeaderItem smallItem">Изменить</div>
                  <div className="tableHeaderItem smallItem">Удалить</div>
                </div>
                <div className="tableBody">
                  {allProducts.map((product) => (
                    <div className="tableBodyItemWrapper" key={product.id}>
                      <div className="tableBodyItem smallItem">
                        {product.id}
                      </div>
                      <div className="tableBodyItem">{product.title}</div>
                      <div className="tableBodyItem">
                        {allCategories.map((category) => {
                          if (category.id === product.category_id) {
                            return category.title;
                          }
                        })}
                      </div>
                      <div className="tableBodyItem">{product.price} сум</div>
                      <div className="tableBodyItem">{product.count}</div>
                      <div className="tableBodyItem smallItem">
                        <div
                          className="change"
                          onClick={() => changeProduct(product.id)}
                        >
                          <img src={updateIcon} alt={updateIcon} />
                        </div>
                      </div>
                      <div className="tableBodyItem smallItem">
                        <div
                          className="remove"
                          onClick={() => removeProduct(product.id)}
                        >
                          <img src={deleteIcon} alt={deleteIcon} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {allSuperCategories.length > 0 && (
            <div
              className={`table ${
                choosenCreateStep === 2 && "choosenStep"
              }`}
            >
              <div className="tableWrapper">
                <div className="tableHeader">
                  <div className="tableHeaderItem smallItem">ID</div>
                  <div className="tableHeaderItem">Наименование</div>
                  <div className="tableHeaderItem smallItem">Изменить</div>
                  <div className="tableHeaderItem smallItem">Удалить</div>
                </div>
                <div className="tableBody">
                  {allSuperCategories.map((superCategoryItem) => (
                    <div
                      className="tableBodyItemWrapper"
                      key={superCategoryItem.id}
                    >
                      <div className="tableBodyItem smallItem">
                        {superCategoryItem.id}
                      </div>
                      <div className="tableBodyItem">
                        {superCategoryItem.title}
                      </div>
                      <div className="tableBodyItem smallItem">
                        <div
                          className="change"
                          onClick={() =>
                            changeSuperCategory(superCategoryItem.id)
                          }
                        >
                          <img src={updateIcon} alt={updateIcon} />
                        </div>
                      </div>
                      <div className="tableBodyItem smallItem">
                        <div
                          className="remove"
                          onClick={() =>
                            removeSuperCategory(superCategoryItem.id)
                          }
                        >
                          <img src={deleteIcon} alt={deleteIcon} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {allCategories.length > 0 && (
            <div
              className={`table ${
                choosenCreateStep === 3 && "choosenStep"
              }`}
            >
              <div className="tableWrapper">
                <div className="tableHeader">
                  <div className="tableHeaderItem smallItem">ID</div>
                  <div className="tableHeaderItem">Наименование</div>
                  <div className="tableHeaderItem">Супер Категория</div>
                  <div className="tableHeaderItem smallItem">Изменить</div>
                  <div className="tableHeaderItem smallItem">Удалить</div>
                </div>
                <div className="tableBody">
                  {allCategories.map((category) => (
                    <div className="tableBodyItemWrapper" key={category.id}>
                      <div className="tableBodyItem smallItem">
                        {category.id}
                      </div>
                      <div className="tableBodyItem">{category.title}</div>
                      <div className="tableBodyItem">
                        {allSuperCategories.map((superCategory) => {
                          if (superCategory.id === category.super_category_id) {
                            return superCategory.title;
                          }
                        })}
                      </div>
                      <div className="tableBodyItem smallItem">
                        <div
                          className="change"
                          onClick={() => changeCategory(category.id)}
                        >
                          <img src={updateIcon} alt={updateIcon} />
                        </div>
                      </div>
                      <div className="tableBodyItem smallItem">
                        <div
                          className="remove"
                          onClick={() => removeCategory(category.id)}
                        >
                          <img src={deleteIcon} alt={deleteIcon} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Outlet />
    </>
  );
}

export default Corporative;
