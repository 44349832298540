import React, { useEffect } from "react";
import { Route, Routes, HashRouter, Navigate, useLocation } from "react-router-dom";
import { APP_ROUTES } from "./Route.js";
import Corporative from "../components/Corporative/Corporative";
import Messenger from "../components/Messanger/Messenger.jsx";
import Referral from "../components/Referral/Referral";
import Login from "../components/Login/Login";
import Bid from "../components/Bid/Bid.jsx";
import Orders from "../components/Orders/Orders.jsx";
import Verification from "../components/Verification/Verification.jsx";
function RequireAuth({ children }) {
  const token = localStorage.getItem("@token");
  const isTokenAvailable = token != null && token !== "";

  let location = useLocation();

  if (!isTokenAvailable) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return children;
  }
}

function Router() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Navigate to={APP_ROUTES.LOGIN} />} />
        <Route path={APP_ROUTES.LOGIN} element={<Login />} />

        <Route
          path={APP_ROUTES.CORPORATIVE}
          element={
            <RequireAuth>
              <Corporative />
            </RequireAuth>
          }
        />
        <Route
          path={APP_ROUTES.BID}
          element={
            <RequireAuth>
              <Bid />
            </RequireAuth>
          }
        />
        <Route
          path={APP_ROUTES.REFERRAL}
          element={
            <RequireAuth>
              <Referral />
            </RequireAuth>
          }
        />
        <Route
          path={APP_ROUTES.ORDERS}
          element={
            <RequireAuth>
              <Orders />
            </RequireAuth>
          }
        />
        <Route
          path={APP_ROUTES.VERIFICATION}
          element={
            <RequireAuth>
              <Verification />
            </RequireAuth>
          }
        />
      </Routes>
    </HashRouter>
  );
}

export default Router;
